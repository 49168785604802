import React from 'react';
import { useTranslation } from 'react-i18next';

import Button  from 'components/shared/button';

import useStyles from 'isomorphic-style-loader/useStyles';
import classes from './style.module.scss';
import globalStyles from 'styles/main.scss';
import './translations';

const Content = ({ customClasses, params:{ aditionalText, showPrice, price, subtitle, informativeText, title, showButton, hasHTML }}) => {
    useStyles(classes, globalStyles);
    const { t } = useTranslation('content');
    
    return (
        <div className={`${classes.container} ${customClasses.container}`}>
            {
                showPrice &&
                    <div className={`${classes.priceBadge} ${customClasses.priceBadge}`}>
                        <span>{ t('from') } </span>
                        <span className={globalStyles.fwBold}> { price } </span>
                    </div>
            }
            {
                title &&
                    <div className={`${classes.content} ${customClasses.content}`}>
                        <div>
                            <span className={`${globalStyles.textUppercase} ${globalStyles.fs10} ${globalStyles.mb10}`}> 
                                { informativeText } 
                            </span>
                        </div>
                        {
                            hasHTML 
                                ? <span 
                                    className={`${classes.title} ${customClasses.title}`} 
                                    dangerouslySetInnerHTML={{__html: title}} 
                                  />
                                : <span className={`${classes.title} ${customClasses.title}`}> { title } </span>
                        }
                        { subtitle && <span className={`${classes.subtitle} ${customClasses.subtitle}`}> { subtitle } </span> }
                        { 
                            aditionalText && 
                                <span className={`${globalStyles.fwBold} ${globalStyles.fs18}`}> 
                                    { aditionalText } 
                                </span>
                            }
                        {
                            showButton &&
                                <div className={`${globalStyles.flexJustifyCenter} ${customClasses.buttonContainer}`}>
                                    <Button>
                                        { t('seeMore') }
                                    </Button>
                                </div>

                        }
                    </div>
            }
        </div>
    )
}

Content.defaultProps = {
    customClasses:{
        container:'',
        content: '',
        title: '',
        subtitle: '',
        buttonContainer: '',
        priceBadge: ''
    }
};

export default Content;